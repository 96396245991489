var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-input", {
    staticClass: "simple-input",
    attrs: {
      type: "number",
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
    },
    on: {
      focus: function ($event) {
        return _vm.$emit("focus")
      },
    },
    model: {
      value: _vm.inputVal,
      callback: function ($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }