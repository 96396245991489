var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "share",
    },
    [
      !_vm.canImprove
        ? _c("div", { staticClass: "share__disabled" })
        : _vm._e(),
      _c("div", { staticClass: "share__free" }, [
        _vm._v(
          " Нераспределенных таймслотов: " + _vm._s(_vm.unimprovedRest) + " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "share__container" },
        [
          _vm._l(_vm.suppliersQuotas, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "share__supplierQuota-container" },
              [
                _c("div", { staticClass: "share__supplierQuota" }, [
                  _c("div", [_vm._v("Поставщик")]),
                  _c("div", { staticClass: "share__supplierQuota-info" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "info__select",
                          { error: item.hasSupplierError },
                        ],
                      },
                      [
                        _c("IqSelect", {
                          attrs: {
                            value: item.supplierId,
                            options: _vm.suppliersList,
                            disabled: Boolean(item.id),
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.clearValidation(index)
                            },
                            input: function ($event) {
                              return _vm.changeSupplierId(index, $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "info__quantity",
                          { error: item.hasQuantityError },
                        ],
                      },
                      [
                        _c("SimpleInputNumber", {
                          attrs: { value: item.quantity },
                          on: {
                            focus: function ($event) {
                              return _vm.clearValidation(index)
                            },
                            input: function ($event) {
                              return _vm.changeSupplierQuantity(index, $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "df" }, [
                      _c(
                        "div",
                        { staticClass: "info__icon" },
                        [
                          _vm.needAccept(index)
                            ? _c("SuccessIcon", {
                                on: {
                                  "icon-clicked": function ($event) {
                                    return _vm.handleSaveQuota(index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "info__icon" },
                        [
                          _c("CloseIcon", {
                            on: {
                              "icon-clicked": function ($event) {
                                return _vm.handleRemoveQuota(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("el-collapse-transition", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.errorText.length,
                          expression: "item.errorText.length",
                        },
                      ],
                      staticClass: "share__error",
                    },
                    [_vm._v(" " + _vm._s(item.errorText) + " ")]
                  ),
                ]),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "share__addQuota",
              on: { click: _vm.handleAddSupplierQuota },
            },
            [
              _c("GreenPlusIcon"),
              _c("span", { staticClass: "share__addQuota-text" }, [
                _vm._v("Добавить квоту на поставщика"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              class: ["share__common", { "share-active": !_vm.commonQuota }],
              on: { click: _vm.handleAddCommonQuota },
            },
            [
              _vm._m(0),
              _c("i", {
                class: [{ rotated: _vm.commonQuota }, "el-icon-arrow-right"],
              }),
            ]
          ),
          _vm.commonQuota
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "share__supplierQuota" }, [
                    _c("div", [_vm._v("Общее")]),
                    _c("div", { staticClass: "share__supplierQuota-info" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "info__commonInput",
                            { error: _vm.commonQuota.errorText.length },
                          ],
                        },
                        [
                          _c("SimpleInputNumber", {
                            attrs: { value: _vm.commonQuota.quantity },
                            on: {
                              input: _vm.changeCommonQuantity,
                              focus: function ($event) {
                                _vm.commonQuota.errorText = ""
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "df" }, [
                        _c(
                          "div",
                          { staticClass: "info__icon" },
                          [
                            !_vm.commonQuota.isAccepted &&
                            _vm.commonQuota.quantity !== undefined
                              ? _c("SuccessIcon", {
                                  on: {
                                    "icon-clicked": function ($event) {
                                      return _vm.handleSaveQuota(-1)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "info__icon" },
                          [
                            _c("CloseIcon", {
                              on: {
                                "icon-clicked": function ($event) {
                                  return _vm.handleRemoveQuota(-1)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-collapse-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.commonQuota.errorText.length,
                            expression: "commonQuota.errorText.length",
                          },
                        ],
                        staticClass: "share__error",
                      },
                      [_vm._v(" " + _vm._s(_vm.commonQuota.errorText) + " ")]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "share__button" },
            [
              _c(
                "iq-button",
                {
                  attrs: {
                    size: "small",
                    disabled: !_vm.canImprove || !_vm.canSave,
                  },
                  on: { onClick: _vm.handleCloseForm },
                },
                [_vm._v(" Закрыть ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "share__common-title" }, [
        _vm._v("Общая квота"),
      ]),
      _c("div", { staticClass: "share__common-description" }, [
        _vm._v(" Доступна для бронирования любому водителю "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }