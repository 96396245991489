<template>
  <el-input
    v-model="inputVal"
    type="number"
    :disabled="disabled"
    class="simple-input"
    :placeholder="placeholder"
    @focus="$emit('focus')"
  />
</template>

<script>
export default {
  name: 'SimpleInputNumber',
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
.simple-input
  display: block
  ::v-deep
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
</style>
